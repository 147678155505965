import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrimeNGModule } from './components/primeng.module';
import { GetFirstWordPipe } from './pipes/firstWord.pipe';
import { PhonePipe } from './pipes/phone.pipe';

// TODO: CRIAR UM ARQUIVO DE CONFIGURACOES DO LOCALE
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNGModule,
    TranslateModule.forRoot(),
    RouterLinkWithHref,
    RouterOutlet,
    PhonePipe,
    GetFirstWordPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    GoogleMapsModule,
    GoogleMap,
    NgxSpinnerModule
  ],
  declarations: [],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt-br'}
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNGModule,
    TranslateModule,
    RouterLinkWithHref,
    RouterOutlet,
    PhonePipe,
    GetFirstWordPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    GoogleMapsModule,
    GoogleMap,
    NgxSpinnerModule
  ],
})
export class SharedModule {}
